import React from 'react';
import { NavBar } from '../components/NavBar';
import { TalkComponent } from '../components/TalkComponent';

export default function Talks(props) {
    const talk_data=[
        {
            name:"Discovering Brains of Webpages",
            url:"https://youtu.be/le49heiezes",
            imagePath:'./images/talk1.webp',
            description:"Workshop on Javascript"
        }
    ]
    return (
        <div>
            <NavBar route='/talks'/>
        <div className="flex flex-col md:px-5">
            <div className="mb-6">
                <h1 className="float-left project-header text-2xl pt-5 xl:text-3xl">
                    Talks
                    <span role="img" aria-label="talks">🎙️</span>
                </h1>
            </div>
            <div className="mx-8 sm:flex sm:flex-wrap md:px-5 xl:px-2">  
                {
                    talk_data.map((item,i)=>(
                        <TalkComponent data={item} key={i}></TalkComponent> 
                    ))
                }
            </div>
        </div>
        </div>
    );
}

