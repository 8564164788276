import React from 'react';
import { TagComponent } from './tagComponent';

export function BlogComponent(props){
    return(
        // sm:w-full md:w-5/12 md:m-5 xl:w-1/4 xl:m-10
        <div className="mb-2 rounded-md bg-white border-2 border-black h-auto flex flex-col items-start p-3 text-left">
            <a href={props.data.githubURL} className="font-bold sm:text-2xl" target='_blank' rel="noreferrer">{props.data.name}</a>
            <p className="text-gray-500 m-0 sm:text-xl">{props.data.description}</p>
            <div className="flex flex-wrap">
                {
                        props.data.tags.map((element,i)=>(
                            <TagComponent data={element} key={i}></TagComponent>
                        ))
                    
                }
        </div>
    </div>
    );
}