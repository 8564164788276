import React from 'react';


export function TalkComponent(props) {
    return (
        <div className="mb-2 rounded-md bg-white border-2 border-black h-auto flex-col sm:w-full md:w-5/12 md:m-5 xl:w-1/4 xl:m-10">
            <div className="project-image-div max-h-120">
                <img src={props.data.imagePath} alt={props.data.name} className="max-h-80 w-full"/>    
            </div>
            <div>
                <span className="font-bold text-2xl ">{props.data.name}{
                    props.data.url?
                    <a href={props.data.url} target='_blank' rel="noreferrer">🔗</a>
                    :""
                }</span>
                <p className="text-gray-500 text-xl ">{props.data.description}</p>
            </div>
        </div>
    );
}
