import React from 'react';
import { NavBar } from '../components/NavBar';

export function HomeScreen(props) {
    
    return (
        <div className="homepage">
            <NavBar/>
        <div className="mx-10 mt-10">
            <div className="text-3xl flex flex-col  sm:text-5xl md:text-6xl lg:text-7xl">
                <div className="my-4 flex mx-auto">Hello<div className="wavehand">👋</div>I'm Jatin</div>
                <div className="my-5">I'm 💻📱 Developer</div>
            </div>
            <div className="flex justify-around w-4/4 mx-auto my-6 items-center md:w-1/2">
                <span className="w-8 md:w-12 lg:w-14"><a href="https://www.linkedin.com/in/jatin-agrawal-968a20184/" target="_blank" rel="noreferrer"><img src="./images/linkedin.webp" alt="linkedIn"/></a></span>
                <span className="w-8 md:w-12 lg:w-14"><a href="https://github.com/JatinAgrawal94" target="_blank" rel="noreferrer"><img src="./images/github.webp" alt="github"/></a></span>
                <span className="w-8 md:w-12 lg:w-14"><a href="mailto: jatinagrawal0801@gmail.com" target="_blank" rel="noreferrer"><img src="./images/gmail.webp" alt="Gmail"/></a></span>
                <span className="w-8 md:w-12 lg:w-14"><a href="https://www.instagram.com/jats____81020" target="_blank" rel="noreferrer"><img src="./images/insta.webp" alt="instagram"/></a></span>
                <span className="w-8 md:w-12 lg:w-14"><a href="https://twitter.com/jats_81020" target="_blank" rel="noreferrer"><img src="./images/twitter.webp" alt="twitter"/></a></span>
            </div>
        </div>
    </div>    
    );
}