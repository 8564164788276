import { NavBar } from '../components/NavBar';
import { ProjectComponent } from '../components/ProjectComponent';

function Projects(props) {    

    const project_data=[
        {
            name:"Amazon-Clone",
            imagePath:"./images/1.webp",
            githubURL:"https://github.com/JatinAgrawal94/Amazon-Clone",
            description:"A simple e-commerce website made using MERN Stack."
        },
        {
            name:"Blog Website",
            imagePath:"./images/2.webp",
            githubURL:"https://github.com/JatinAgrawal94/Blog-System",
            description:"A Website on which one can manage its own blogs."
        },
        {
            name:"Flutter Calculator",
            imagePath:"./images/3.webp",
            githubURL:"https://github.com/JatinAgrawal94/Flutter_Calculator",
            description:"A Calculator App made using Flutter"
        },
        {
            name:"Instagram Automation",
            imagePath:"./images/instagram_automation.webp",
            githubURL:"https://github.com/JatinAgrawal94/Instagram-Automation-Tool",
            description:"A tool to automate usage of Instagram"
        },
        {
            
            name:"CareConnect",
            imagePath:"./images/careconnect.webp",
            githubURL:"https://github.com/JatinAgrawal94/CareConnect",
            description:"A Mobile App made with Flutter and Firebase that helps people manage their medical records."
        },
        {
            
            name:"ContributeForMind",
            imagePath:"./images/contribute.webp",
            githubURL:"https://github.com/JatinAgrawal94/ContributeForMind",
            description:"A WebApp that provides resources regarding mental health issues.Also provides a platform to those who want to help those people in need."
        }
    ]

    return (
        <div>
            <NavBar route='/projects'/>
        <div className="flex flex-col md:px-5 xl:px-5">
            <div className="mb-6">
                <h1 className="float-left project-header text-2xl pt-5 xl:text-3xl">
                    Projects
                    <span role="img" aria-label="projects">💻</span>
                </h1>
            </div>
            <div className="mx-8 sm:flex sm:flex-wrap md:px-5 xl:px-2">  
                {
                    project_data.map((item,i)=>(
                        <ProjectComponent data={item} key={i}></ProjectComponent> 
                    ))
                }
            </div>
        </div>
        </div>
    );
}

export default Projects;