import React from 'react';
import {Link} from 'react-router-dom';

export function NavBar(props){
    return (
        <nav className="fixed... flex  justify-between w-full text-2xl border-b-2 p-3">
            <Link className="p-2 text-black hover:text-black ... " to='/'>Jatin</Link>
            <div className="flex">
            <Link className={props.route === '/projects'? "p-2 underline hover:text-black ... ":"p-2 no-underline hover:underline hover:text-black ... " } to='/projects'>Projects</Link>
            <Link className={props.route === '/talks'? "p-2 underline hover:text-black ... ":"p-2 no-underline hover:underline hover:text-black ... " } to='/talks'>Talks</Link>
            <Link className={props.route === '/blogs'? "p-2 underline hover:text-black ... ":"p-2 no-underline hover:underline hover:text-black ... " } to='/blogs'>Blogs</Link>
            </div>
            </nav>
    );
}