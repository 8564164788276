import './App.css';
import {BrowserRouter,Route} from 'react-router-dom';
import {HomeScreen} from './screens/HomeScreen';
import Talks from './screens/Talks';
import Projects from './screens/Projects';
import Blogs from './screens/Blogs';
import { Footer } from './components/footer';

function App() {
  return (
    <BrowserRouter>
    <div className="App" >
      <Route path='/talks' component={Talks} ></Route>
      <Route path='/projects' component={Projects} ></Route>
      <Route path='/blogs' component={Blogs}></Route>
      <Route path='/' component={HomeScreen} exact></Route>
      <Footer></Footer>
    </div>
    </BrowserRouter>
  );
}

export default App;