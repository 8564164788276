import React from 'react';


export function ProjectComponent(props) {
    
    return (
        <div className="mb-2 rounded-md bg-white border-2 border-black  flex-col sm:w-full md:w-5/12 md:m-5 xl:w-1/4 xl:m-10">
            <div className="project-image-div h-40 sm:h-80 md:h-60">
                <img src={props.data.imagePath} alt={props.data.name} className="object-fill w-full h-40 sm:h-80 md:h-60 lg"/>    
            </div>
            <div>
                <span className="font-bold text-2xl ">{props.data.name}<a href={props.data.githubURL} target='_blank' rel="noreferrer">🔗</a></span>
                <p className="text-gray-500 text-xl ">{props.data.description}</p>
            </div>
        </div>
    );
}
