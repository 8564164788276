import { BlogComponent } from '../components/BlogComponent';
import { NavBar } from '../components/NavBar';

function Blogs(props) {    

    const blog_data=[
        {
            name:"Instagram-Automation Using Selenium Webdriver",
            imagePath:"./images/1.webp",
            githubURL:"https://jatinagrawal0801.medium.com/instagram-automation-tool-using-selenium-web-driver-e854ef8dbca2",
            description:"A Tool made using Selenium WebDriver to automate instagram tasks.",
            tags:['Instagram','Selenium','Python','Web-Automation']
        },
    ]

    return (
        <div>
            <NavBar route='/blogs'/>
        <div className="flex flex-col md:px-5 xl:px-5">
            <div className="mb-6">
                <h1 className="float-left project-header text-2xl pt-5 xl:text-3xl">
                    Blogs
                    <span role="img" aria-label="projects">✏️</span>
                </h1>
            </div>
            <div className="mx-8 md:px-5 xl:px-2">  
                {
                    blog_data.map((item,i)=>(
                        <BlogComponent data={item} key={i} ></BlogComponent> 
                    ))
                }
            </div>
        </div>
        </div>
    );
}

export default Blogs;